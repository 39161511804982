.show--detail--main--container {
	position: relative;
	height: 100%;
	overflow-x: hidden !important;
	padding: 0 0 3rem 0;
}

.show--detail--main--container--data--description {
	padding: 8px 0;
	/* font-family: Mulish; */
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0em;
	text-align: justified;
}

.show--detail--main--container--data--share {
	margin: 10px 0;
}

.container--selected--podcast {
	position: fixed;
	bottom: -120px;
	left: 0;
	transition: all 0.3s;
}

.show--detail--main--container--episodes {
	/* width: 100vw; */
}

.container--selected--podcast.opened--selected--podcast {
	bottom: 0;
}
