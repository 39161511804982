.slider-container {
	--progress-bar-height: 4px;
}

.slider-container {
	position: relative;
	width: 100%;
}

.slider-container::before {
	content: "";
	background-color: rgb(61, 34, 214);
	width: 99%;
	height: calc(var(--progress-bar-height) - 1px);
	display: block;
	position: absolute;
	border-radius: 10px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
}

.progress-bar-cover {
	background-color: rgb(218, 55, 145);
	width: 0%;
	height: var(--progress-bar-height);
	display: block;
	position: absolute;
	border-radius: 10px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 1;
	user-select: none;
	pointer-events: none;
}

.range {
	-webkit-appearance: none;
	background-color: rgb(61, 34, 214);
	height: 10px;
	width: 100%;
	cursor: pointer;
	opacity: 0;
	margin: 0 auto;
}

.slider-container-timer-left {
	position: absolute;
	top: 1.5rem;
	left: 0;
}
.slider-container-timer-right {
	position: absolute;
	top: 1.5rem;
	right: 0;
}

.timer {
	font-size: 10px;
	font-weight: 600;
	color: rgb(61, 34, 214);
}
