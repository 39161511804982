.creator--element--main--container {
	font-size: 13px;
	white-space: nowrap;
	min-width: 180px;
	color: #645faa;
	border: 0.5px solid #645faa;
	border-radius: 20px;
	cursor: pointer;
	overflow: hidden;
	transition: all 0.45s;
}

.creator--element--main--container.selected--creator--element {
	background: #645faa;
	color: white;
}

@media (min-width: 451px) {
	.creator--element--main--container {
		padding: 10px 15px;
		margin: 5px 0;
	}
}

@media (max-width: 450px) {
	.creator--element--main--container {
		margin: 0 5px 0 0;
		padding: 10px 15px;
	}
}
