.episode--main--container--detail {
	padding: 5px 0 0 10px;
}

.episode--main--container--detail--title {
	/* font-family: Mulish; */
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: left;
}

.episode--main--container--detail--index {
	/* font-family: Mulish; */
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0em;
	text-align: left;
	color: #645faa;
}

.episode--main--container--detail--date {
	/* font-family: Mulish; */
	font-size: 9px;
	font-style: normal;
	font-weight: 400;
	line-height: 11px;
	letter-spacing: 0em;
	text-align: left;
	color: #a39e9e;
}
