* {
	margin: 0;
	padding: 0;
	/* box-sizing: border-box; */
}

@font-face {
	font-family: IBMPlexSansBold;
	src: url('assets/fonts/IBMPlexSans-Bold.ttf');
}

@font-face {
	font-family: IBMPlexSansExtraLight;
	src: url('assets/fonts/IBMPlexSans-ExtraLight.ttf');
}

@font-face {
	font-family: IBMPlexSansLight;
	src: url('assets/fonts/IBMPlexSans-Light.ttf');
}

@font-face {
	font-family: IBMPlexSansMedium;
	src: url('assets/fonts/IBMPlexSans-Medium.ttf');
}

@font-face {
	font-family: IBMPlexSansRegular;
	src: url('assets/fonts/IBMPlexSans-Regular.ttf');
}

@font-face {
	font-family: IBMPlexSansThin;
	src: url('assets/fonts/IBMPlexSans-Thin.ttf');
}
