
.app__header{
    position: fixed;
    max-height: 200px;
    background: #004CFF;
    width: 100vw;
    z-index: 10;
}

.app__header--logo{
    width: 395px;
    margin-left: 125px;
}

.app_header__action{
    float: right;
    margin-right: 10%;
    margin-top: 3%;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
}

@media (max-width: 900px) {
    .app__header{
        max-height: 100px;
        width: 100%;
    }
    .app__header--logo{
        width: 40%;
        margin-left: 50px;
    }
    .app_header__action{
        font-weight: 400 !important;
        font-size: 10px;
        margin-top: 3%;
    }
}
