.episode--reproduce--container {
	height: 100vh;
	width: 100%;
	position: relative;
}

.episode--reproduce--container--blur {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	backdrop-filter: blur(6px);
}

.episode--reproduce--container--blur--image {
	height: 100%;
	width: 100%;
	object-fit: cover;
	-webkit-filter: blur(25px);
}

.episode--reproduce--container--view {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 100;
}

.episode--reproduce--container--view--audio {
	height: 50px;
	width: 250px;
	margin-bottom: 50px;
}

.episode--reproduce--container--controls {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 155px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	background: rgba(255, 255, 255, 0.41);
	backdrop-filter: blur(4px);
	border-radius: 38px 38px 0px 0px;
	padding-top: 30px;
	z-index: 300;
}
