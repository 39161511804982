.episode--main--container--play {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 0 10px;
}

.episode--main--container--play--background {
}

.episode--main--container--play--background--internal {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.episode--main--container--play--background--internal--icon {
	padding-left: 2px;
}
