.dashboard--main--container {
	position: relative;
	height: 100%;
}

.dashboard--main--container--user--icon {
	position: absolute;
	top: 10px;
	right: 10px;
}
