.show--element--main--container {
	min-height: 220px !important;
	min-width: 170px !important;
	overflow: hidden;
	position: relative;
	margin: 0 5px 0 0;
}

.show--element--main--container--image {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.show--element--main--container--background {
	position: absolute;
	bottom: 6px;
	right: 6px;
	width: 55px;
	height: 55px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(255, 255, 255, 0.87);
	backdrop-filter: blur(4px);
	border-radius: 22px;
}

.show--element--main--container--background--image {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.show--element--main--container--background--play {
	position: absolute;
	top: 0;
	left: 2px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.show--element--main--container--background--play--image {
	height: 15px;
	width: 15px;
}
