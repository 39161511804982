/* LOADER */
.container-spinner {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.lds-spinner {
	display: inline-block;
	position: relative;
	height: 80px;
	width: 75px;
}

.lds-spinner div {
	animation: lds-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: 40px 40px;
}
.lds-spinner div:after {
	content: " ";
	display: block;
	position: absolute;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #423994;
	margin: -4px 0 0 -4px;
}
.lds-spinner div:nth-child(1) {
	animation-delay: -0.036s;
}
.lds-spinner div:nth-child(1):after {
	top: 63px;
	left: 63px;
}
.lds-spinner div:nth-child(2) {
	animation-delay: -0.072s;
}
.lds-spinner div:nth-child(2):after {
	top: 68px;
	left: 56px;
}
.lds-spinner div:nth-child(3) {
	animation-delay: -0.108s;
}
.lds-spinner div:nth-child(3):after {
	top: 71px;
	left: 48px;
}
.lds-spinner div:nth-child(4) {
	animation-delay: -0.144s;
}
.lds-spinner div:nth-child(4):after {
	top: 72px;
	left: 40px;
}
.lds-spinner div:nth-child(5) {
	animation-delay: -0.18s;
}
.lds-spinner div:nth-child(5):after {
	top: 71px;
	left: 32px;
}
.lds-spinner div:nth-child(6) {
	animation-delay: -0.216s;
}
.lds-spinner div:nth-child(6):after {
	top: 68px;
	left: 24px;
}
.lds-spinner div:nth-child(7) {
	animation-delay: -0.252s;
}
.lds-spinner div:nth-child(7):after {
	top: 63px;
	left: 17px;
}
.lds-spinner div:nth-child(8) {
	animation-delay: -0.288s;
}
.lds-spinner div:nth-child(8):after {
	top: 56px;
	left: 12px;
}
@keyframes lds-spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
