.bottom--nav--main--container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	height: 100%;
	width: 100%;
	background: black;
}

.nav--item--main--container {
}
