.control--panel--main--container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.container--new--control {
	border-radius: 10px;
	border: 0.5px solid rgb(6, 5, 5);
	padding: 5px;
	height: 37px;
	width: 37px;
	cursor: pointer;
	margin: 0 0.3rem;
}
