.router--styles--main--container {
	position: relative;
	height: 100%;
}

.router--styles--main--container--bottom--nav {
	position: fixed;
	bottom: 0;
	left: 0;
	height: 60px;
	width: 100%;
	border-top-left-radius: 35px;
	border-top-right-radius: 35px;
	transition: all 0.3s;
	overflow: hidden;
	z-index: 1000;
}

.router--styles--main--container--bottom--nav.open--bottom--nav {
	bottom: 0;
}

.router--styles--container--user {
	position: fixed;
	bottom: -220px;
	left: 0;
	transition: all 0.3s;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
	height: 200px;
	width: 100%;
	overflow: hidden;
}

.router--styles--container--user.user--open {
	bottom: 0;
}
