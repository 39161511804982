.dashboard--main--container--company {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 1rem;
}

.dashboard--main--container--company--image {
	height: 80px;
	width: 80px;
}
