.previuos--main--container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
	margin: 1.2rem 0;
}

.previuos--main--container--image {
}

.previuos--main--container--text {
	/* font-family: Mulish; */
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 23px;
	letter-spacing: 0.02rem;
	text-align: left;
	color: #645faa;
	padding: 0 0.25rem;
	text-decoration: underline;
}
