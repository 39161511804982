.horizontal--show--list--main--container {
	display: flex;
	flex-direction: row;
	align-items: center;
	/* width: 100%; */
	overflow-x: auto !important;
	margin: 15px 0;
}

.horizontal--show--list--main--container::-webkit-scrollbar {
	width: 0px;
	height: 0px;
	background: transparent;
}

.horizontal--show--list--main--container::-webkit-scrollbar-thumb {
	border-radius: 2px;
	height: 10px;
	box-shadow: inset 0 0 3px transparent;
}

.horizontal--show--list--main--container:hover::-webkit-scrollbar {
	background: transparent;
}
