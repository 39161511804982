.episode--main--container--image {
	width: 65px;
	height: 64px;
}

.episode--main--container--image > img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	border-radius: 20px;
}
