.episode--reproduce--container--goback {
	position: absolute;
	top: 12px;
	left: 12px;
	height: 15px;
	width: 15px;
	z-index: 150;
}

.episode--reproduce--container--goback > img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}
