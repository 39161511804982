.episode--main {
	padding-bottom: 1rem;
	border-bottom: 0.5px solid rgb(210, 209, 209);
	margin: 2rem 0;
}

.episode--main--container {
	display: grid;
	grid-template-columns: 1fr 7fr 1fr;
	grid-template-rows: 70px;
	margin: 10px 0;
}
